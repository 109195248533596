<template>
  <CModal
    :show="isCommunicationModalOpen"
    @hide="closeCommunicationModal"
    size="xl"
  >
    <template v-slot:header>
      <h3 style="padding-left: 1rem">
        {{ isEditMode ? $t("Edit News") : $t("Add News") }}
      </h3>
      <CButton @click="closeCommunicationModal"
        ><i class="fa fa-times"></i
      ></CButton>
    </template>
    <template v-slot:footer>
      <div class="d-flex justify-content-between w-100 align-items-center">
        <label for="mandatory">Mandatory*</label>
        <div>
          <CButton @click="closeCommunicationModal" class="link">{{
            $t("Cancel")
          }}</CButton>
          <CButton
            @click="addCommunication"
            color="primary"
            class="modal-confirm"
            data-testid="saveCommunicationButton"
            :disabled="isDisabled"
          >
            {{ isEditMode ? $t("Edit News") : $t("Add News") }}
          </CButton>
        </div>
      </div>
    </template>
    <div class="modal-body communication-modal">
      <div style="flex: 1; padding-right: 10px">
        <ScopeSelections
          :show="isCommunicationModalOpen"
          :preSelectedUsers="selectedCommunicationUsers"
          @usersChanged="selectedUsersChanged"
          @levelsChanged="selectedLevelsChanged"
          @rolesChanged="selectedRolesChanged"
          @selectAll="selectedAllUsers"
        />
        <div
          class="d-flex justify-content-between align-items-center gap-5"
          style="margin-bottom: 10px"
        >
          <div style="flex: 1">
            <label>{{ $t("Name") }}*</label>

            <el-input
              data-testid="communicationName"
              v-model="communicationName"
              :placeholder="$t('News Name')"
            />
          </div>
          <div class="w-25" v-if="!isEditMode">
            <label>{{ $t("Active News") }}</label>
            <RaSwitch
              color="#0071ce"
              size="sm"
              :checked="isSwitchOn"
              class="ml-5 mt-2"
              v-c-tooltip="{
                content: TurnStatus(),
                placement: 'top',
              }"
              @change="isSwitchOn = !isSwitchOn"
            />
          </div>
        </div>
        <label>{{ $t("Upload Image") }}*</label>
        <FileDragDrop
          :allowedExtensions="allowedExtensions"
          data-testid="imageUploadInput"
          size="xs"
          class="w-100 mb-2"
          label=""
          @upload="handleUpload"
        />
        <div v-if="uploadedFile && !isEditMode">
          <div class="d-flex">
            <p class="filename-text">{{ uploadedFile.name }}</p>
          </div>
        </div>

        <div v-if="isEditMode">
          <div v-if="editedUploadedFiles.length > 0">
            <div class="d-flex">
              <p class="filename-text">{{ uploadedFile.name }}</p>
            </div>
          </div>
          <div v-else>
            <img
              :src="getImageSrc()"
              alt="News Image"
              style="width: 100%; object-fit: cover"
            />
          </div>
        </div>
      </div>
      <div style="width: 50%; padding-left: 10px; height: 280px">
        <label>{{ $t("News description") }}*</label>
        <QuillEditor
          v-if="!isEditMode"
          theme="snow"
          @textChange="onEditorBlur"
          @ready="onReadyEditor"
          toolbar="minimal"
        />
        <QuillEditor
          v-else
          :content="communicationDescription"
          contentType="html"
          theme="snow"
          @textChange="onEditorBlur"
          @ready="onReadyEditor"
          toolbar="minimal"
        />
      </div>
    </div>
  </CModal>
</template>

<script>
import { FilesService } from "@/services/azureFiles";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { CommunicationService } from "@/services/communications";
import { OrganizationsService } from "@/services/organizations";
import { NewsService } from "@/services/news";
import { showMessage } from "../../../utils/helpers";
import { mapState } from "vuex";
import Treeselect from "vue3-treeselect";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import ScopeSelections from "@/components/ScopeSelections";
import { useGlobalStore } from "@/stores/store";
import { useUserStore } from "@/stores/UserStore";
import { ElLoading } from "element-plus";

export default {
  name: "AddEditCommunication",
  components: {
    vSelect,
    Treeselect,
    QuillEditor,
    ScopeSelections,
  },
  props: {
    isCommunicationModalOpen: Boolean,
    communicationToEdit: Object,
    isEditMode: Boolean,
    commId: Number,
  },
  data() {
    return {
      userStore: useUserStore(),
      globalStore: useGlobalStore(),
      selectedUsers: [],
      selectedLevels: [],
      selectedRoles: [],
      usersOptions: [],
      selectedFilter: "users",
      communicationName: "",
      communicationDescription: "",
      isSwitchOn: true,
      uploadedFiles: [],
      communications: [],
      editedUploadedFiles: [],
      allowedExtensions: ["jpeg", "jpg", "png"],
      quill: null,
      delta: {},
      selectedCommunicationUsers: [],
      selectedAll: false,
      uploadUrl: "",
      fileId: "",
      uploadedFile: null,
    };
  },
  created() {
    this.getOrgMembers();
    this.checkEditMode();
  },
  watch: {
    isCommunicationModalOpen(newVal) {
      if (!newVal) {
        this.resetFields();
      }
    },
    communicationToEdit(newVal) {
      if (newVal) {
        this.communicationName = newVal.name || "";
        this.communicationDescription = newVal.description || "";
        this.isSwitchOn = newVal.active;
        this.uploadedFile = {
          name: newVal.image_name,
          id: newVal.image_id,
        };
        this.selectedCommunicationUsers = newVal.users;
      }
    },
  },
  computed: {
    ...mapState({
      selectedEvent: (state) => state.selectedEvent,
      selectedTraining: (state) => state.selectedTraining,
      userData: (state) => state.userData,
      currentOrganization: (state) => state.selectedOrg,
      currentProject: (state) => state.selectedProject,
      includedOrgs: (state) => state.includedOrgs,
      allOrgs: (state) => state.allOrgs,
    }),
    isDisabled() {
      this.selectedUsers = this.selectedUsers || [];
      this.selectedLevels = this.selectedLevels || [];
      this.selectedRoles = this.selectedRoles || [];
      const isFileEmpty = !this.uploadedFile;

      const areCommonConditionsNotMet =
        !this.communicationName.trim() ||
        !this.communicationDescription.replace(/<[^>]*>/g, "").trim() ||
        isFileEmpty;

      const isScopeEmpty =
        this.selectedUsers.length === 0 &&
        this.selectedLevels.length === 0 &&
        this.selectedRoles.length === 0;

      return areCommonConditionsNotMet || (isScopeEmpty && !this.selectedAll);
    },
    tooltipContent() {
      return this.isSwitchOn ? this.$t("Turn On") : this.$t("Turn On");
    },
    isProjectSelectionDisabled() {
      return (
        !this.currentOrganization.name ||
        this.currentOrganization.organization ||
        this.currentOrganization.name ||
        this.currentOrganization.organization === "All"
      );
    },
  },
  mounted() {
    this.checkEditMode();
  },
  methods: {
    selectedUsersChanged(selectedUsers) {
      this.selectedUsers = selectedUsers;
    },
    selectedLevelsChanged(selectedLevels) {
      this.selectedLevels = selectedLevels;
    },
    selectedRolesChanged(selectedRoles) {
      this.selectedRoles = selectedRoles;
    },
    selectedAllUsers(selectAll) {
      this.selectedAll = selectAll;
    },
    onReadyEditor(quill) {
      this.quill = quill;
    },
    onEditorBlur(editor) {
      this.delta = this.quill.editor.delta;
      this.communicationDescription =
        this.quill.editor.scroll.domNode.innerHTML;
    },

    async getOrgMembers() {
      try {
        const { data } = await OrganizationsService.list_org_members({
          organization_name:
            this.currentOrganization.name ||
            this.currentOrganization.organization,
          project_name: this.currentProject.name,
        });
        const usersOptions = [];
        const response = data.data;
        if (this.usersOptions.length > 0) {
          this.usersOptions = [];
        }
        const seenUserNames = new Set();
        response.forEach((user) => {
          const userName = `${user.firstname} ${user.lastname}`;
          if (!seenUserNames.has(user._id)) {
            seenUserNames.add(user._id);
            usersOptions.push({
              value: user._id,
              label: userName,
            });
          }
        });
        this.globalStore.setScopeUsersOptions(usersOptions);
        this.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
    getImageSrc() {
      const myBlobToken = this.userStore.blobToken;
      const imageId = this.uploadedFile.id;
      return `https://stmzgdsengage.blob.core.windows.net/news/${imageId}?${myBlobToken}`;
    },
    resetFields() {
      this.quill.editor.scroll.domNode.innerHTML = "";
      this.selectedUsers = [];
      this.communicationName = "";
      this.communicationDescription = "";
      this.isSwitchOn = true;
      this.uploadedFile = "";
      this.selectedCommunicationUsers = [];
    },
    TurnStatus() {
      if (this.isSwitchOn) {
        return "Turn Off";
      } else {
        return "Turn On";
      }
    },
    checkEditMode() {
      if (this.communicationToEdit) {
        this.isEditMode = true;
        this.communicationName = this.communicationToEdit.comm_name || "";
        this.communicationDescription =
          this.communicationToEdit.description || "";
      }
    },
    closeCommunicationModal() {
      this.resetFields();
      this.uploadedFiles = [];
      this.$emit("close");
    },
    async addCommunication() {
      const usersToSend = this.selectedAll
        ? this.globalStore.scopeUsersOptions.map((user) => user.value)
        : this.selectedUsers;

      try {
        if (this.isEditMode) {
          let editPayload = {
            id: this.communicationToEdit.id,
            name: this.communicationName,
            description: this.communicationDescription,
            active: this.isSwitchOn,
            featured: true,
            delta: this.delta,
            users: usersToSend,
            levels: this.selectedLevels,
            roles: this.selectedRoles,
          };

          // Check if a new file is uploaded
          if (this.editedUploadedFiles.length > 0) {
            const uploadFileData = await FilesService.UploadFile(
              this.uploadedFile,
              this.uploadUrl
            );
            if (uploadFileData.success) {
              editPayload.image_id = this.fileId;
              editPayload.image_name = this.uploadedFile.name;
            } else {
              showMessage("error", "Failed to upload the new file!");
              return;
            }
          } else {
            editPayload.image_id = this.uploadedFile.id;
            editPayload.image_name = this.uploadedFile.name;
          }

          const { data, res } = await NewsService.update_news(editPayload);
          this.editedUploadedFiles = [];
          this.$parent.getCommunicationList();
          if (res.status === 200) {
            showMessage("success", "News updated successfully!");
            this.$emit("edit", data);
          } else {
            showMessage("error", "Failed to update News!");
          }
        } else {
          const loading = ElLoading.service({
            fullscreen: true,
            background: "#0a1f8f45",
          });
          if (this.uploadedFile) {
            const uploadFileData = await FilesService.UploadFile(
              this.uploadedFile,
              this.uploadUrl
            );
            loading.close();
            if (uploadFileData.success) {
              const myPayload = {
                name: this.communicationName,
                description: this.communicationDescription,
                active: this.isSwitchOn,
                featured: true,
                delta: this.delta,
                image_id: this.fileId,
                image_name: this.uploadedFile.name,
                users: usersToSend,
                levels: this.selectedLevels,
                roles: this.selectedRoles,
              };
              const { data, res } = await NewsService.create_news(myPayload);
              this.$parent.getCommunicationList();

              if (res.status === 200) {
                showMessage("success", "News created successfully!");
              }
              this.$emit("add", data);
            }
          } else {
            const myPayload = {
              name: this.communicationName,
              description: this.communicationDescription,
              active: this.isSwitchOn,
              featured: true,
              delta: this.delta,
              users: usersToSend,
              levels: this.selectedLevels,
              roles: this.selectedRoles,
            };
            const { data, res } = await NewsService.create_news(myPayload);
            this.$parent.getCommunicationList();

            if (res.status === 200) {
              showMessage("success", "News created successfully!");
            }
            this.$emit("add", data);
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
      this.closeCommunicationModal();
      this.resetFields();
    },
    async handleUpload(file) {
      const maxSize = 10 * 1024 * 1024;

      if (file.size > maxSize) {
        showMessage("error", "File size should not exceed 10 MB");
        return;
      }
      const data = await FilesService.getUploadUrl();
      this.uploadUrl = data.upload_url;
      this.fileId = data.file_id;
      this.uploadedFile = file;
      if (this.isEditMode) {
        this.editedUploadedFiles.push(file.name);
      }
    },
  },
};
</script>

<style scoped>
::v-deep .modal.show .modal-dialog {
  left: unset !important;
}
.confirm-btn {
  background-color: #0071ce;
  border-radius: 0px 0px 0px 25px;
  height: 40px;
}
.filter_btn {
  cursor: pointer;
  font-size: 14px;
}
.filter_btn:hover {
  color: #0071ce;
}
.filter_btn.selected {
  color: #0071ce;
  font-weight: bold;
  letter-spacing: -0.02em;
  border-bottom: 2px solid #0071ce;
}
.filename-text {
  font-size: 12px !important;
}
.modal-body.communication-modal {
  display: flex;
  gap: 20px;
  overflow: auto;
  min-height: 520px !important;
}
.modal-body {
  padding: 0 1rem !important;
}
::v-deep .modal-header {
  padding: 1rem 1rem 0 1rem !important;
}
.modal-confirm {
  border-radius: 0px 0px 0px 25px;
  padding: 10px;
  width: 105px;
  margin-left: 10px;
}

::v-deep .el-select__wrapper {
  border-radius: 0;
}

::v-deep .el-input__wrapper {
  border-radius: 0;
}
::v-deep .el-textarea__inner {
  border-radius: 0;
}
::v-deep .modal-footer {
  padding: 0.75rem 39px !important;
}
::v-deep .modal-dialog {
  top: unset !important;
  left: unset !important;
}
</style>
