<template>
  <CModal
    :show="showConfirmDeletion"
    @hide="closeDeleteModal"
    :top="true"
    :draggable="false"
  >
    <template v-slot:header>
      <h3>{{ $t("Confirm Deletion") }}</h3>
      <CButton @click="closeDeleteModal">
        <i class="fa fa-times"></i>
      </CButton>
    </template>
    <template v-slot:footer>
      <CButton @click="closeDeleteModal" class="link">{{
        $t("Cancel")
      }}</CButton>
      <CButton
        @click="confirmDeletion"
        color="danger"
        class="modal-confirm"
        data-testid="confirmCommunicationDelete"
      >
        {{ $t("Delete") }}
      </CButton>
    </template>
    <p>{{ $t("Are you sure you want to delete this News") }}?</p>
  </CModal>
</template>
<script>
export default {
  props: {
    showConfirmDeletion: Boolean,
  },
  methods: {
    closeDeleteModal() {
      this.$emit("close");
    },
    confirmDeletion() {
      this.$emit("confirm");
      this.closeDeleteModal();
    },
  },
};
</script>
<style scoped>
.modal-confirm {
  width: 100px;
  border-radius: 0px 0px 0px 25px !important;
}
</style>
