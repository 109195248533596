import { BASE_URL } from "@/constants/config";
import { request } from "@/utils/request";
import { ORGS_ENDPOINTS } from "./organizations";

export const COMMUNICATION_ENDPOINTS = {
  create_communication: `${BASE_URL}api/communications/create_communication`,
  set_communication_active: `${BASE_URL}api/communications/set_communication_active`,
  update_communication: `${BASE_URL}api/communications/update_communication`,
  delete_communication: `${BASE_URL}api/communications/delete_communication`,
  list_communications: `${BASE_URL}api/communications/list_communications`,
  get_comm: (id) => `${BASE_URL}api/communications/${id}`,
  get_communication_details: `${BASE_URL}api/communications/get_communication_details`,
};

class Communication {
  async create_communication({
    org_name,
    comm_name,
    description,
    file_name,
    deadline,
    active,
    delta,
    users,
    levels,
    roles,
  }) {
    var { data, res } = await request({
      url: COMMUNICATION_ENDPOINTS.create_communication,
      method: "PUT",
      skipRedirect: true,
      body: {
        org_name,
        comm_name,
        description,
        file_name,
        deadline,
        active,
        delta,
        users,
        levels,
        roles,
      },
    });
    return { data, res };
  }

  async set_communication_active({ _id, active }) {
    var { data, res } = await request({
      url: COMMUNICATION_ENDPOINTS.set_communication_active,
      method: "PATCH",
      skipRedirect: true,
      body: {
        _id,
        active,
      },
    });
    return { data, res };
  }

  async list_communications({ org_name, org_id }) {
    var { data, res } = await request({
      url: COMMUNICATION_ENDPOINTS.list_communications,
      method: "POST",
      skipRedirect: true,
      body: {
        org_name,
        org_id,
      },
    });
    return { data, res };
  }

  async delete_communication(ids) {
    var { data, res } = await request({
      url: COMMUNICATION_ENDPOINTS.delete_communication,
      method: "DELETE",
      skipRedirect: true,
      body: ids,
    });
    return { data, res };
  }

  async update_communication({ _id, update_data, users, levels, roles }) {
    var { data, res } = await request({
      url: COMMUNICATION_ENDPOINTS.update_communication,
      method: "PUT",
      skipRedirect: true,
      body: {
        _id,
        update_data,
        users,
        levels,
        roles,
      },
    });
    return { data, res };
  }

  async get_communication_details({ _id }) {
    var { data, res } = await request({
      url: COMMUNICATION_ENDPOINTS.get_communication_details,
      method: "POST",
      skipRedirect: true,
      body: {
        _id,
      },
    });
    return { data, res };
  }
  async get_details(id) {
    var { data, res } = await request({
      url: `${COMMUNICATION_ENDPOINTS.get_comm(id)}`,
      method: "GET",
      skipRedirect: true,
    });
    return {
      data,
      res,
    };
  }
}

export const CommunicationService = new Communication();
